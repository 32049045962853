<template>
  <div class="bg-gray-200">
    <div class="flex justify-center items-center min-h-screen">
      <nuxt />
    </div>
    <LayoutFooter />
  </div>
</template>

<script>
import LayoutFooter from '@/partials/LayoutFooter'

export default {
  name: 'LayoutBlank',
  components: { LayoutFooter },
  head () {
    const domain = document.location.hostname

    const domainName = domain.replaceAll('.', '-')

    return {
      title: this.$t(`seoStuff.${domainName}.title`),
      meta: [
        { hid: 'description', name: 'description', content: this.$t(`seoStuff.${domainName}.description`) }
      ]
    }
  }
}
</script>
