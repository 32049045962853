<template>
  <div v-if="rerender">
    <div
      class="bg-trans-black-08 fixed top-0 left-0 w-full h-screen transition duration-300 ease-in-out backdrop-filter backdrop-blur-sm"
      style="z-index: 55"
      :class="[getMetaSidebarState ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0']"
    />
    <AppHeader class="sticky top-0 z-60 block" />
    <div class="flex flex-col pageWrapper">
      <PageHeader />
      <div v-if="$route.name !== 'blocklist'" class="pageLayout__body py-10 mx-8 md:mx-14 flex-auto relative">
        <div class="container">
          <nuxt />
        </div>
      </div>
      <div v-else class="bg-white">
        <nuxt />
      </div>
    </div>
    <InformationCard />
    <ScreenOverlay :screen-open="screenOpen" @closeScreen="closeScreen" />
    <LayoutFooter />
    <SecondScreen />
    <WebSocketHandler />
    <LiveChatComp />
    <portal-target name="customSidebar" />
    <tsx-contentscreen />
    <ReportFalsePositive />
  </div>
</template>

<script>
import { computed, onMounted, ref, useContext, useStore, watch } from '@nuxtjs/composition-api'
import { useCookies } from '@vueuse/integrations/useCookies'
import mitt from 'mitt'
import ScreenOverlay from '@/components/base/ScreenOverlay/ScreenOverlay'
import InformationCard from '@/components/feature/InformationCard/InformationCard'
import SecondScreen from '@/components/feature/SecondScreen/SecondScreen'
import WebSocketHandler from '@/components/provider/WebSocketHandler/WebSocketHandler'
import LayoutFooter from '@/partials/LayoutFooter'
import PageHeader from '~/components/feature/PageHeader/PageHeader'
import ReportFalsePositive from '~/components/feature/ReportFalsePositive/ReportFalsePositive'
import amplitudeTracker from '~/composeables/amplitudeTracker/amplitudeTracker'
import { changelogStore } from '~/pstore/changelog'
import { debugEcho } from '~/utils/debugTools'
import AppHeader from '~/components/feature/AppHeader/AppHeader.vue'
import useSetupLanguage from '~/composeables/setupLanguage/setupLanguage'
import LiveChatComp from '~/components/feature/LiveChatComp/LiveChatComp.vue'

export default {
  name: 'LayoutDefault',
  components: { ReportFalsePositive, PageHeader, InformationCard, ScreenOverlay, SecondScreen, LayoutFooter, WebSocketHandler, AppHeader, LiveChatComp },
  middleware: ['Heimdal', 'gtm'],
  setup () {
    window.mitt = window.mitt || mitt()
    const { i18n } = useContext()
    const store = useStore()

    const COOKIE_NAME = 'locale'
    const domain = ref('')
    const rerender = ref(true)

    const nav = computed(() => store.getters['navigation/getNavPath'])
    const userDetails = computed(() => store.getters['access/getUserDetails'])
    const systemName = computed(() => store.getters['projects/getActualSystemName'])
    const getMetaSidebarState = computed(() => store.getters['ui/getMetaSidebarState'])
    const screenOpen = computed(() => store.getters['ui/getSecondScreenState'])
    const subscription = computed(() => store.getters['access/getUserSubscription'])
    const closeScreen = () => (store.dispatch('ui/closeScreens'))
    const cookies = useCookies([COOKIE_NAME])
    const currentLanguage = ref(i18n.locale)
    const current360ApplicationLanguage = cookies.get(COOKIE_NAME)
    const whitelabelConfig = computed(() => store.getters['whitelabel/getConfig'])
    const clStore = changelogStore()

    const { changeLanguage } = useSetupLanguage()

    onMounted(() => {
      debugEcho('onMounted whitelabelConfig', whitelabelConfig.value.cname)
      domain.value = process.env.whiteLabelDomain.length
        ? process.env.whiteLabelDomain
        : document.location.hostname
      if (current360ApplicationLanguage && current360ApplicationLanguage !== currentLanguage.value) {
        changeLanguage(current360ApplicationLanguage)
      }
      store.dispatch('whitelabel/setReady', { isReady: false })
      setTimeout(() => {
        store.dispatch('whitelabel/setReady', { isReady: true })
      }, 1000)
    })

    watch(() => subscription.value, () => {
      if (process.client && subscription.value) {
        const plan = subscription.value?.trial_end > 0
          ? 'trial'
          : subscription.value.subscription_plan
        window.usetifulTags = {
          plan
        }
      }
    }, { immediate: true })

    const { tracker } = amplitudeTracker()

    const screenEvents = (action) => {
      debugEcho('ACTION RECEIVED', action)
      const events = {
        readingChangelogs: () => {
          clStore.deleteAllChangelogEntries()
          tracker('360m', { platform: 'FSC', message: 'read current changelog' })
        },
        closeStoreIframe: async () => {
          debugEcho('closeStoreIframe', action)
          debugEcho('Details', userDetails.value)
          await store.dispatch('access/fetchSubscriptionAndPlanData')
          localStorage.removeItem('app_header_data')
          localStorage.removeItem('app_header_timestamp')
        }
      }

      return events[action]?.() || (() => { })
    }
    window.mitt.on('tsxContentScreenEvents', ({ action }) => {
      screenEvents(action)
    })

    return {
      domain,
      nav,
      systemName,
      getMetaSidebarState,
      screenOpen,
      closeScreen,
      currentLanguage,
      currentEnvironment: process.env.NODE_ENV,
      rerender,
      clStore
    }
  },
  head () {
    const flatnav = this.$store.getters['navigation/getFlatnav']
    const whitelabelConfig = this.$store.getters['whitelabel/getConfig']

    const { name } = this.$route
    const domain = document.location.hostname.replaceAll('.', '-')
    const subtitle = flatnav[name] ? `${flatnav[name].pageName || flatnav[name].groupId} - ` : ''

    const isRealPartner = whitelabelConfig.partnerType === 'partner'
    const cname = whitelabelConfig.cname.replaceAll('.', '-') || ''
    const partnerCompanyName = isRealPartner ? whitelabelConfig.companyName : null
    const translationName = isRealPartner ? 'partnerDefault' : cname || domain
    const translationKey = `seoStuff.${translationName}`

    const seoTitleKey = `${translationKey}.title`
    const seoDescriptionKey = `${translationKey}.description`

    const title = this.$te(seoTitleKey)
      ? isRealPartner
        ? this.$t(seoTitleKey, { brand: partnerCompanyName })
        : this.$t(seoTitleKey)
      : this.$t('seoStuff.sitecheck-platform360-io.title')
    const description = this.$te(seoDescriptionKey)
      ? isRealPartner
        ? this.$t(seoDescriptionKey, { brand: partnerCompanyName })
        : this.$t(seoDescriptionKey)
      : this.$t('seoStuff.sitecheck-platform360-io.description')

    return {
      title: `${subtitle} ${title}`,
      meta: [
        { hid: 'description', name: 'description', content: description }
      ],
      link: [
        { rel: 'icon', type: 'image/x-icon', href: whitelabelConfig.faviconUrl }
      ]
    }
  }
}
</script>

<style lang="postcss">
.pageWrapper {
  min-height: calc(100vh - 60px);
}
.pageLayout {
  &__header {
    width: 100px;

    @media screen and (min-width: 769px) {
      width: 400px;
    }
  }

  &__body {
    max-width: 1650px;
    min-width: 240px;
  }
}
</style>
