<template>
  <div class="">
    <header class="mb-10">
      <h1 class="text-2xl">
        {{ error.statusCode === 404 ? $t('pageNotFound') : $t('errorOccured') }}
      </h1>
      <p v-if="error.statusCode !== 404" class="mt-4 max-w-screen-md hyphens font-light">
        <strong>{{ $t('errorMessage') }}: </strong>{{ error.message }}
      </p>
    </header>
    <GeneralButton link-path="/dashboard">
      {{ $t('backToOverview') }}
    </GeneralButton>
  </div>
</template>

<script>
import GeneralButton from '~/components/base/GeneralButton/GeneralButton'

export default {
  name: 'ErrorPage',
  components: { GeneralButton },
  props: {
    error: {
      type: [String, Object],
      default: ''
    }
  }
}
</script>
